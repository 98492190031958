import ClassCard from "app/components/ClassCard";
import { ClassCardSkeleton } from "app/components/ClassCard/ClassCardSkeleton";
import { Slider } from "app/components/Slider";
import { Routes } from "constants/routes";
import { isEmpty } from "lodash";
import React from "react";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { Section } from "../Section";
import { ContinueDancingEmptyState } from "./EmptyState";

type ContinueDancingProps = {
  data: object[];
  loading?: boolean;
};
export const ContinueDancing = ({ data, loading }: ContinueDancingProps) => {
  const loadingStateComponent = () => {
    return (
      <Slider
        data={[{}, {}, {}, {}, {}]}
        slideComponent={ClassCardSkeleton}
        slidesSpacing={24}
        cardHeight="190px"
      />
    );
  };

  return (
    <Section
      title="Continue Dancing"
      showLoadingState={loading}
      showEmptyState={isEmpty(data)}
      showTitleLoadingState
      emptyStateComponent={<ContinueDancingEmptyState />}
      loadingStateComponent={loadingStateComponent()}
      headerLink={{
        route: Routes.history,
        text: "See All",
      }}
    >
      <Slider
        data={data}
        slideComponent={(props: any) => (
          <ClassCard
            {...props}
            container={HomeSelectContentContainer.ContinueDancing}
            selectedFrom="ContinueDancing"
          />
        )}
        selectedFrom="ContinueDancing"
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
};
