import { Watch } from "app/components/Icon";
import React from "react";
import { EmptyState } from "../EmptyState";

export const ContinueDancingEmptyState = () => {
  return (
    <EmptyState
      icon={Watch}
      title="your first class"
      description="and it will show up here"
    />
  );
};
